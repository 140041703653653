@use "../abstracts/variables" as *;

main {
	position: relative;
	display: grid;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
	
	background-color: $color-primary;
	background-repeat: no-repeat;
	background-size: 100%;
	grid-template-columns: repeat(8, 1fr);
	grid-template-rows: repeat(7, 1fr);
	
	&::before {
		position: absolute;
		top: 0;
		right: -75%;
		width: 100%;
		height: 100%;
		content: '';
		transform: skewX(-25deg);
		background: $color-secondary;
	}
}
