@use "../abstracts/variables" as *;

.rectangle {
	width: 0.8em;
	fill: $color-secondary;
	
	
	&.primary {
		fill: $color-primary;
	}
}
