// Vertical Line animations
@keyframes lineGrow {
	0% {
		top: 50%;
		height: 0;
	}
	100% {
		top: -25%;
		height: 150%;
	}
}


// dot swap animations
@keyframes dotSwap1 {
	0% {
		top: 0%;
		left: 0%;
	}
	25% {
		top: 0%;
		left: 100%;
	}
	50% {
		top: 100%;
		left: 100%;
	}
	75% {
		top: 100%;
		left: 0%;
	}
	100% {
		top: 0%;
		left: 0%;
	}
}

@keyframes dotSwap2 {
	0% {
		top: 0%;
		left: 100%;
	}
	25% {
		top: 100%;
		left: 100%;
	}
	50% {
		top: 100%;
		left: 0%;
	}
	75% {
		top: 0%;
		left: 0%;
	}
	100% {
		top: 0%;
		left: 100%;
	}
}

@keyframes dotSwap3 {
	
	0% {
		top: 100%;
		left: 100%;
	}
	25% {
		top: 100%;
		left: 0%;
	}
	50% {
		top: 0%;
		left: 0%;
	}
	75% {
		top: 0%;
		left: 100%;
	}
	100% {
		top: 100%;
		left: 100%;
	}
}

@keyframes dotSwap4 {
	0% {
		top: 100%;
		left: 0%;
	}
	25% {
		top: 0%;
		left: 0%;
	}
	50% {
		top: 0%;
		left: 100%;
	}
	75% {
		top: 100%;
		left: 100%;
	}
	100% {
		top: 100%;
		left: 0%;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}


.spin {
	transform: rotate(0deg);
	animation: spin 2s infinite linear;
	
	&-slow {
		transform: rotate(0deg);
		animation: spin 4s infinite linear;
	}
}

.spin-reverse {
	transform: rotate(0deg);
	animation: spin 2s infinite linear reverse;
	
	&-slow {
		transform: rotate(0deg);
		animation: spin 4s infinite linear reverse;
	}
}
