/*
 * Colors
 */
$color-primary: rgba(71, 42, 128, 1);
$color-secondary: rgba(230, 87, 113, 1);

$color-white: rgba(255, 255, 255, 1);

/*
 * Typography
 */
$font-weight-normal: 300;
$font-weight-bold: 700;
