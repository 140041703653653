@use "../abstracts/variables" as *;

.triangle {
	width: 2.5em;
	fill: $color-secondary;
	
	&.big {
		width: 3em;
	}
	
	&.primary {
		fill: $color-primary;
	}
}
