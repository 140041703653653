.socials {
	position: relative;
	display: grid;
	align-self: end;
	margin: 0;
	padding: 0;
	list-style: none;
	grid-area: 6 / 2 / span 1 / span 2;
	grid-template-columns: repeat(4, 2.5em);
	grid-template-rows: 2.5em;
	gap: 1em;
	
	li {
		display: flex;
		flex-direction: row;
	}
	
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		
		&:hover {
			.icon {
				transform: translate3d(0, 0, 0);
			}
		}
	}
	
	img {
		width: 2em;
	}
	
	.icon {
		z-index: 1;
		transition: all 0.25s ease-in-out;
		transform: translate3d(-0.15em, -0.15em, 0);
	}
	
	.shadow {
		position: absolute;
	}
}
