@use "../abstracts/variables" as *;

.dots {
	position: relative;
	display: block;
	width: 2em;
	
	&.primary {
		.dot {
			background-color: $color-primary;
		}
	}
	
	&.swap,
	&.swap-reverse {
		.dot {
			animation-duration: 3s;
			animation-iteration-count: infinite;
			
			&-1 {
				animation-name: dotSwap1;
			}
			
			&-2 {
				animation-name: dotSwap2;
			}
			
			&-3 {
				animation-name: dotSwap3;
			}
			
			&-4 {
				animation-name: dotSwap4;
			}
		}
	}
	
	&.swap-reverse {
		.dot {
			animation-direction: reverse;
		}
	}
}

.dots-inner {
	position: relative;
	display: block;
	width: 100%;
	aspect-ratio: 19 / 9;
}

.dot {
	position: absolute;
	display: block;
	width: 24%;
	border-radius: 50%;
	background-color: $color-secondary;
	aspect-ratio: 1 / 1;
	
	&-1 {
		top: 0;
		left: 0;
	}
	
	&-2 {
		top: 0;
		left: 100%;
	}
	
	&-3 {
		top: 100%;
		left: 100%;
	}
	
	&-4 {
		top: 100%;
		left: 0;
	}
}
